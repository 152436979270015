import Axios from "./api";

const clienttickets = (set, get) => ({
  clientTickets: {
    clientTickets: null,
    ticket: null,
    content: null,
    orderDetails: null,
    failedDetails: null,
    loading: false,
    singleLoading: false,
    editLoading: false,
    deleteLoading: false,
    addLoading: false,
    addImgLoading: false,
    closeLoading: false,
    query: { page: 0, size: 10, sort: "createTime,desc" },
  },
  superUser: {
    username: null,
    phone: null,
    isLoading: false,
  },

  resetClientTicketQuery: () => {
    set({
      clientTickets: {
        ...get().clientTickets,
        query: { page: 0, size: 10, sort: "createTime,desc" },
      },
    });
  },
  setClientTicketQuery: query => {
    set({
      clientTickets: {
        ...get().clientTickets,
        query: { ...get().clientTickets.query, ...query },
      },
    });
  },
  getClientTicket: async id => {
    try {
      set({
        clientTickets: { ...get().clientTickets, singleLoading: true },
      });
      const res = await Axios.get(`/client/ticket?ticketId=${id}`);
      //console.log('clientTickets', res);
      if (res.status === 200) {
        set({
          clientTickets: {
            ...get().clientTickets,
            singleLoading: false,
            ticket: res.data,
          },
        });
      }
    } catch (error) {
      set({
        clientTickets: { ...get().clientTickets, singleLoading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
  addClientTicket: async values => {
    get().resetClientTicketQuery();
    set({
      clientTickets: { ...get().clientTickets, addLoading: true },
    });
    try {
      const res = await Axios.post(`/client/support`, values);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  addClientReply: async (id, content) => {
    set({
      clientTickets: { ...get().clientTickets, addLoading: true },
    });
    try {
      const res = await Axios.post(
        `/client/ticket?ticketId=${id}&content=${content}`
      );
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },

  addClientReplyByImage: async (id, form) => {
    set({
      clientTickets: { ...get().clientTickets, addImgLoading: true },
    });
    try {
      const res = await Axios.post(
        `/client/replyByImage?ticketId=${id}`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      set({
        clientTickets: { ...get().clientTickets, addImgLoading: false },
      });
      return res;
    } catch (error) {
      set({
        clientTickets: { ...get().clientTickets, addImgLoading: false },
      });
      return error.response;
    }
  },

  closeClientTicket: async id => {
    set({
      clientTickets: { ...get().clientTickets, closeLoading: true },
    });
    try {
      const res = await Axios.patch(`/client/ticket?ticketId=${id}`);
      set({
        clientTickets: { ...get().clientTickets, closeLoading: false },
      });
      return res;
    } catch (error) {
      set({
        clientTickets: { ...get().clientTickets, closeLoading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },

  getFailedDetails: async id => {
    try {
      const res = await Axios.get(
        `/storeManagement/failedCardInfo?failedId=${id}`
      );
      const array = [];
      //console.log('clientTickets', res);
      if (res.status === 200) {
        array.push(res?.data);
        set({
          clientTickets: {
            ...get().clientTickets,
            failedDetails: array,
          },
        });
      }
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },

  reteSupportReply: async data => {
    try {
      const res = await Axios.post("/client/supportRating", {
        rating: data.rating,
        feedback: data.feedback,
        replyId: data.id,
      });
      return res;
    } catch (error) {
      throw error;
    }
  },

  getSuperUser: async () => {
    set({
      superUser: { ...get().superUser, isLoading: true },
    });
    try {
      const res = await Axios.get("/users/sub/superUser");
      const { username, phone } = res.data;

      set({
        superUser: { ...get().superUser, username, phone },
      });
    } catch (error) {
    } finally {
      set({
        superUser: { ...get().superUser, isLoading: false },
      });
    }
  },
});

export default clienttickets;
