import Axios from "./api";

const statistic = (set, get) => ({
  statistic: {
    lastOrdersStatistic: null,
    mostConsumers: null,
    content: null,
    loading: false,
    usersCount: null,
    usersCountLoading: false,
    query: null,
    lastOrdersLoading: false,
    mostConsumersLoading: false,
    failedCounter: null,
    failedCountLoading: false,
    newUsers: {
      users: null,
      content: null,
      loading: false,
      query: { page: 0, size: 10, sort: "createTime,desc" },
    },
    deletedUsers: {
      users: null,
      content: null,
      loading: false,
      query: { page: 0, size: 10, sort: "createTime,desc" },
    },
    customersProfit: {
      customers: null,
      content: null,
      loading: false,
      query: { page: 0, size: 10, sort: "createTime,desc" },
    },
    query: { page: 0, size: 10, sort: "createTime,desc" },
  },

  resetOrderStatisticQuery: () => {
    set({
      statistic: {
        ...get().statistic,
        query: { page: 0, size: 10, sort: "createTime,desc" },
      },
    });
  },
  setOrderStatisticQuery: query => {
    set({
      statistic: {
        ...get().statistic,
        query: { ...get().statistic.query, ...query },
      },
    });
  },
  resetNewUsersQuery: () => {
    set({
      statistic: {
        ...get().statistic,
        newUsers: {
          ...get().newUsers,
          query: { page: 0, size: 10, sort: "createTime,desc" },
        },
      },
    });
  },
  setNewUsersQuery: query => {
    set({
      statistic: {
        ...get().statistic,
        newUsers: {
          ...get().newUsers,
          query: query,
        },
      },
    });
  },
  resetDeletedUsersQuery: () => {
    set({
      statistic: {
        ...get().statistic,
        deletedUsers: {
          ...get().deletedUsers,
          query: { page: 0, size: 10, sort: "createTime,desc" },
        },
      },
    });
  },
  setDeletedUsersQuery: query => {
    set({
      statistic: {
        ...get().statistic,
        deletedUsers: {
          ...get().deletedUsers,
          query: query,
        },
      },
    });
  },
  setCustomersProfitQuery: query => {
    set({
      statistic: {
        ...get().statistic,
        customersProfit: {
          ...get().customersProfit,
          query: query,
        },
      },
    });
  },
});

export default statistic;
