"use client";

import Image from "next/future/image";

const Logo = ({ width = 300, className, isSmall = false }) => {
  const imageClassNames = `transition-[transform,width,max-height] duration-300 ease-in-out max-h-full ${className}`;
  const height = isSmall ? width : width / 6.35;

  return (
    <>
      {!isSmall && (
        <Image
          src="/h-logo.png"
          width={width}
          height={height}
          alt="digital home"
          className={imageClassNames + " hidden dark:inline"}
          priority={true}
        />
      )}

      {!isSmall && (
        <Image
          src="/h-logo-black.png"
          width={width}
          height={height}
          alt="digital home"
          className={imageClassNames + " inline dark:hidden"}
          priority={true}
        />
      )}

      {isSmall && (
        <Image
          src="/digital-home.png"
          width={width}
          height={height}
          alt="digital home"
          className={imageClassNames}
          priority={true}
        />
      )}
    </>
  );
};

export default Logo;
