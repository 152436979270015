import Axios from "./api";

const ordersFlexyGros = (set, get) => ({
  ordersFlexyGros: {
    orders: null,
    content: null,
    consumptions: null,
    loading: false,
    consumptionsLoading: false,
    query: { page: 0, size: 10, sort: "createTime,desc" },
  },

  resetOrderFlexyGrosState: () => {
    set({
      ordersFlexyGros: {
        ...get().ordersFlexyGros,
        orders: null,
        content: null,
        consumptions: null,
        loading: false,
        consumptionsLoading: false,
        query: { page: 0, size: 10, sort: "createTime,desc" },
      },
    });
  },

  resetOrderFlexyGrosQuery: () => {
    set({
      ordersFlexyGros: {
        ...get().ordersFlexyGros,
        query: { page: 0, size: 10, sort: "createTime,desc" },
      },
    });
  },

  setOrderFlexyGrosQuery: query => {
    set({
      ordersFlexyGros: {
        ...get().ordersFlexyGros,
        query: { ...get().ordersFlexyGros.query, ...query },
      },
    });
  },

  getAllFlexyGrosOrders: async (loading = true) => {
    try {
      set({
        ordersFlexyGros: { ...get().ordersFlexyGros, loading },
      });
      const params = {
        ...get().ordersFlexyGros.query,
      };
      const res = await Axios.get(`/orders/flexyGros`, { params });

      if (res.status === 200) {
        set({
          ordersFlexyGros: {
            ...get().ordersFlexyGros,
            content: res.data,
            orders: [...res.data?.content],
            loading: false,
          },
        });
      }
    } catch (error) {
      set({
        ordersFlexyGros: { ...get().ordersFlexyGros, loading: false },
      });
      return error.response;
    }
  },

  updateLastFlexyGrosOrder: async (orderId, updatedOrder) => {
    const filteredOrders = get().ordersFlexyGros.orders.filter(
      order => order.id !== orderId
    );
    const newOrders = [updatedOrder, ...filteredOrders];

    set({
      ordersFlexyGros: {
        ...get().ordersFlexyGros,
        content: { ...get().ordersFlexyGros, content: newOrders },
        orders: newOrders,
      },
    });
  },

  getFlexyGrosConsumptions: async (loading, start, end) => {
    try {
      set({
        ordersFlexyGros: {
          ...get().ordersFlexyGros,
          consumptionsLoading: loading,
        },
      });
      const params = { start, end };
      const res = await Axios.get("/orders/flexyGrosConsumptions", { params });
      if (res.status === 200) {
        set({
          ordersFlexyGros: {
            ...get().ordersFlexyGros,
            consumptionsLoading: false,
            consumptions: res.data,
          },
        });
      }
    } catch (error) {
      set({
        ordersFlexyGros: {
          ...get().ordersFlexyGros,
          consumptionsLaoading: false,
        },
      });
      return error.response;
    }
  },

  orderFlexyGros: async body => {
    try {
      const res = await Axios.post(`/orders/flexyGros`, body);
      return res;
    } catch (err) {
      return err.response;
    }
  },

  resendFlexyGrosOrder: async orderId => {
    const res = await Axios.post(`/orders/reSendFlexyGros`, null, {
      params: { orderId },
    });
    return res;
  },
});

export default ordersFlexyGros;
