import { privateRoutes } from "@/data/privateRoutes";
import Paragraph from "@/ui/Paragraph";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { MobileSidebar } from "../sidebar/MobileSidebar";
import DownloadPWA from "./DownloadPWA";
import NotificationsMenu from "./NotificationsMenu";
import ShowBalanceToggle from "./ShowBalanceToggle";
import SettingsMenu from "./SettingsMenu";
import { useTranslation } from "react-i18next";
import FreezeAccountWarning from "./FreezeAccountWarning";

const Navbar = () => {
  const { t } = useTranslation("layout");
  const { pathname } = useRouter();
  const basePath = "/" + pathname.split("/")[1];
  const isDashboardPage = basePath === "/dashboard";

  const title = useMemo(
    () => privateRoutes.find(route => route.path === basePath),
    [basePath]
  )?.name;

  return (
    <header className="sticky top-0 z-30 md:static">
      <DownloadPWA />
      <FreezeAccountWarning />
      <div className="flex justify-between h-14 items-center gap-4 border-b bg-background p-4 md:pb-0 px-4 md:h-auto md:border-0 md:bg-transparent md:px-6">
        <div className="flex items-center gap-2">
          <MobileSidebar />

          <Paragraph
            variant="subTitle"
            className="lowercase first-letter:capitalize"
          >
            {t(title)}
          </Paragraph>
        </div>

        <div className="flex items-center gap-2">
          {isDashboardPage && <ShowBalanceToggle />}
          <NotificationsMenu />
          <SettingsMenu />
        </div>
      </div>
    </header>
  );
};

export default Navbar;
