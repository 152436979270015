import Axios, { storeRequest } from "./api";

const publicities = (set, get) => ({
  publicities: {
    publicities: null,
    singlePublicity: null,
    content: null,
    loading: false,
    singleLoading: false,
    deleteLoading: false,
  },
  storePublicity: {
    content: null,
    loading: false,
  },
  storeStores: {
    content: null,
    loading: false,
  },
  // /publicities/images/upload
  uploadPublicityImage: async (publicityId, data) => {
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("mobileFile", data.mobileFile);
    try {
      const res = await Axios.post(
        `/publicities/images/upload`,
        formData,
        { params: { publicityId } },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  getAllPublicity: async (loading, id, query) => {
    try {
      set({
        publicities: { ...get().publicities, loading },
      });
      const params = {
        ...query,
        sort: "createTime,desc",
      };
      const res = await Axios.get(`/publicities`, { params });
      //console.log('publicities', res);
      if (res.status === 200) {
        set({
          publicities: {
            ...get().publicities,
            content: res.data,
            loading: false,
            publicities: res.data,
          },
        });
      }
    } catch (error) {
      set({
        publicities: { ...get().publicities, loading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },

  getStorePublicity: async () => {
    try {
      set({
        storePublicity: { ...get().storePublicity, loading: true },
      });

      const res = await storeRequest.get("/home");
      set({
        storePublicity: {
          ...get().storePublicity,
          content: res.data,
          loading: false,
        },
      });
    } catch (error) {
      set({
        storePublicity: { ...get().storePublicity, loading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },

  getStoreStores: async () => {
    try {
      set({
        storeStores: { ...get().storeStores, loading: true },
      });

      const res = await storeRequest.get("/share/stores?offset=1&limit=1000");
      set({
        storeStores: {
          ...get().storeStores,
          content: res.data,
          loading: false,
        },
      });
    } catch (error) {
      set({
        storeStores: { ...get().storeStores, loading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
  getSinglePublicity: async id => {
    try {
      set({
        publicities: { ...get().publicities, singleLoading: true },
      });
      const res = await Axios.get(`/publicities/${id}`);
      //console.log('single publicities', res);
      if (res.status === 200) {
        set({
          publicities: {
            ...get().publicities,
            content: res.data,
            singleLoading: false,
            singlrPublicity: res.data?.content,
          },
        });
      }
    } catch (error) {
      set({
        publicities: { ...get().publicities, singleLoading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
  createPublicity: async body => {
    try {
      const res = await Axios.post(`/publicities`, body);
      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  deletePublicity: async id => {
    set({
      publicities: { ...get().publicities, deleteLoading: true },
    });
    try {
      const res = await Axios.delete(`/publicities/${id}`);
      //console.log(res);
      set({
        publicities: { ...get().publicities, deleteLoading: false },
      });
      return res;
    } catch (error) {
      set({
        publicities: { ...get().publicities, deleteLoading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
  updatePublicityName: async (id, name) => {
    try {
      const res = await Axios.patch(`/publicities/${id}?name=${name}`);
      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  updatePublicityPhoto: async (id, url) => {
    try {
      const res = await Axios.patch(
        `/publicities/updatePhoto/${id}?url=${url}`
      );
      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
});

export default publicities;
