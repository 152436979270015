import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";

i18n
  .use(HttpBackend) // Load translation files dynamically
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "ar", // Default language
    defaultNS: "common", // Set your default namespace if you have one
    ns: [
      "common",
      "symbols",
      "ui-components",
      "dashboard",
      "orders",
      "storage",
      "offline",
    ],
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json", // Correct path
      // Increment the version manually whenever you update the translations. to solve the problem of fetching translation from cache
      queryStringParams: { v: "1.0.8" },
    },
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
