import { privateRoutes } from "@/data/privateRoutes";
import { cn } from "@/lib/utils";
import useStore from "@/store";
import { useMemo } from "react";
import Logo from "../../Logo";
import LinkItem from "./LinkItem";
import ProfileMenu from "./ProfileMenu";

const Content = ({ isSmall, setIsMobileSidOpen }) => {
  const user = useStore(state => state.currentUser?.user);
  const navPaths = useMemo(
    () =>
      privateRoutes.filter(
        route => route.icon && route.roles.includes(user?.roles[0]?.name)
      ),
    [user?.roles[0].name]
  );

  const isAccountActivate = useStore(state => state.isAccountActivate);
  const isPhoneVerified = useStore(state => state.isPhoneVerified);
  const isPinCreated = useStore(state => state.isPinCreated);

  return (
    <div
      className={cn(
        "overflow-y-auto overflow-x-hidden px-[24px] h-[calc(100%-81px)] w-full transition-[padding] duration-100 md:duration-300 ease-in-out",
        isSmall && "px-[4px]"
      )}
    >
      <div
        className={cn(
          "min-h-[92px] max-h-[92px] min-w-[54px] transition-[transform,width,max-heigh,opacity] duration-100 md:duration-300 ease-in-out grid place-items-center overflow-hidden pointer-events-auto"
        )}
      >
        <Logo isSmall={isSmall} width={isSmall ? 40 : 230} />
      </div>

      {isAccountActivate && isPhoneVerified && isPinCreated && (
        <ul className="flex flex-col gap-2">
          {navPaths.map(link => {
            return (
              <LinkItem
                onClick={
                  setIsMobileSidOpen ? () => setIsMobileSidOpen(false) : null
                }
                isSmall={isSmall}
                key={link.path}
                link={link}
              />
            );
          })}
        </ul>
      )}

      {user && (
        <ProfileMenu
          isSmall={isSmall}
          user={user}
          setIsMobileSidOpen={setIsMobileSidOpen}
        />
      )}
    </div>
  );
};

export default Content;
