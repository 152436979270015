import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/DropdownMenu";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/Tabs";
import WithLoader from "@/components/ui/WithLoader";
import useFetch from "@/hooks/useFetch";
import useStore from "@/store";
import { Button } from "@/ui/Button";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { IoMdNotificationsOutline } from "react-icons/io";
import NotificationItem from "./NotificationItem";
import { useTranslation } from "react-i18next";

const NotificationsMenu = () => {
  const { t } = useTranslation("layout");
  const [newNotifications, setNewNotifications] = useState([]);
  const [oldNotifications, setOldNotifications] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { pathname } = useRouter();
  const getAllNotifications = useStore(state => state.getAllNotifications);
  const getCountOfUnreadNotifications = useStore(
    state => state.getCountOfUnreadNotifications
  );
  const resetQuery = useStore(state => state.resetNotificationsQuery);
  const markAsReadNotification = useStore(
    state => state.markAsReadNotification
  );

  const { value: allNotifications, isLoading } = useFetch(
    handleGetNotifications,
    [isMenuOpen]
  );
  const {
    value: unreadNotificationsCount,
    setValue: setUnreadNotificationsCount,
  } = useFetch(getCountOfUnreadNotifications, [pathname]);

  async function handleGetNotifications() {
    if (isMenuOpen) {
      resetQuery();
      const res = await getAllNotifications();
      markAsReadNotification();
      return res.content;
    }
  }

  const handleOpenMenuChange = open => {
    setIsMenuOpen(open);
    if (!open) {
      setNewNotifications([]);
      setOldNotifications([]);
    }
  };

  useEffect(() => {
    if (!allNotifications) return;

    if (unreadNotificationsCount > 0) {
      const unreadNot = allNotifications.slice(0, unreadNotificationsCount);
      setNewNotifications(unreadNot);
      const oldNot = allNotifications.slice(unreadNotificationsCount);
      setOldNotifications(oldNot);
      setUnreadNotificationsCount(0);
    } else {
      setOldNotifications(allNotifications);
    }
  }, [allNotifications]);

  return (
    <DropdownMenu open={isMenuOpen} onOpenChange={handleOpenMenuChange}>
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="icon"
          className="text-[1.5rem] rounded-full p-0 h-9 w-9 relative"
        >
          {unreadNotificationsCount > 0 && (
            <div className="bg-red-500 text-white rounded-full text-xs leading-4 grid place-items-center min-h-3 min-w-3 px-[7px] py-[2px] absolute top-0 right-0 transform translate-x-[8px] -translate-y-[8px]">
              {unreadNotificationsCount}
            </div>
          )}

          <IoMdNotificationsOutline />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        className="z-[99] w-[400px] max-h-[500px] overflow-y-auto max-sm:max-w-[93vw] ltr:max-sm:translate-x-[-3.5vw] rtl:max-sm:translate-x-[-3.5vw] pt-2 pb-6 px-1 md:px-2"
        align="end"
        forceMount
      >
        <div className="flex items-center w-full justify-between">
          <DropdownMenuLabel>{t("notifications.title")}</DropdownMenuLabel>
          {allNotifications?.length > 0 && (
            <Link href="/notifications" passHref>
              <Button
                className="px-2"
                onClick={() => setIsMenuOpen(false)}
                variant="link"
              >
                {t("notifications.all")}
              </Button>
            </Link>
          )}
        </div>

        <Tabs
          defaultValue={unreadNotificationsCount > 0 ? "new" : "old"}
          className="w-full"
        >
          <TabsList className="w-full my-2">
            <TabsTrigger className="flex-1" value="new">
              {t("notifications.new")}
            </TabsTrigger>
            <TabsTrigger className="flex-1" value="old">
              {t("notifications.read")}
            </TabsTrigger>
          </TabsList>
          <WithLoader className="my-16" isLoading={isLoading}>
            <TabsContent value="new">
              {newNotifications?.length ? (
                newNotifications.map((notification, index) => (
                  <Fragment key={notification.id}>
                    <NotificationItem
                      onClick={() => setIsMenuOpen(false)}
                      notification={notification}
                    />
                    {index !== newNotifications.length - 1 && (
                      <DropdownMenuSeparator />
                    )}
                  </Fragment>
                ))
              ) : (
                <span className="text-center py-16 text-muted-foreground block">
                  {t("notifications.no_new_notifications")}
                </span>
              )}
            </TabsContent>
            <TabsContent value="old">
              {oldNotifications?.length ? (
                oldNotifications.map((notification, index) => (
                  <Fragment key={notification.id}>
                    <NotificationItem
                      onClick={() => setIsMenuOpen(false)}
                      notification={notification}
                    />
                    {index !== oldNotifications.length - 1 && (
                      <DropdownMenuSeparator />
                    )}
                  </Fragment>
                ))
              ) : (
                <span className="text-center py-16 text-muted-foreground block">
                  {t("notifications.no_new_notifications")}
                </span>
              )}
            </TabsContent>
          </WithLoader>
        </Tabs>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default NotificationsMenu;
