import Axios from "./api";

const deposits = (set, get) => ({
  deposits: {
    deposits: null,
    content: null,
    loading: false,
    query: { page: 0, size: 10, sort: "createdTime,desc" },
  },

  resetDepositQuery: () => {
    set({
      deposits: {
        ...get().deposits,
        query: { page: 0, size: 10, sort: "createdTime,desc" },
      },
    });
  },
  setDepositQuery: query => {
    set({
      deposits: {
        ...get().deposits,
        query: { ...get().deposits.query, ...query },
      },
    });
  },
  getAllDeposits: async loading => {
    try {
      set({
        deposits: {
          ...get().deposits,
          loading: loading || true,
        },
      });
      const params = {
        ...get().deposits.query,
      };

      const res = await Axios.get(`/deposits`, { params });
      if (res.status === 200) {
        set({
          deposits: {
            ...get().deposits,
            content: res.data,
            loading: false,
            deposits: res?.data.content,
          },
        });
      }
    } catch (error) {
      set({
        deposits: { ...get().deposits, loading: false },
      });
      return error.response;
    }
  },

  addDeposit: async amount => {
    try {
      set({
        deposits: { ...get().deposits, loading: true },
      });
      const res = await Axios.post("/deposits/ePay", null, {
        params: {
          amount,
        },
      });
      if (res.status === 200) {
        set({
          deposits: {
            ...get().deposits,
            content: res.data,
            loading: false,
            deposits: res.data?.content,
          },
        });
      }
      return res;
    } catch (error) {
      set({
        deposits: { ...get().deposits, loading: false },
      });
      throw error;
    }
  },
});

export default deposits;
