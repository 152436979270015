import logdown from "logdown";
import Axios from "./api";

const logger = logdown("categories");
logger.state.isEnabled = false;

const categories = (set, get) => ({
  categories: {
    categories: null,
    content: null,
    loading: false,
    editLoading: false,
    deleteLoading: false,
    addLoading: false,
    query: { page: 0, size: 10, sort: "createTime,desc" },
  },
  resetCategoriesQuery: () => {
    set({
      categories: {
        ...get().categories,
        query: { page: 0, size: 10, sort: "createTime,desc" },
      },
    });
  },
  setCategoriesQuery: query => {
    set({
      categories: {
        ...get().categories,
        query: { ...get().categories.query, ...query },
      },
    });
  },

  uploadCategoryImage: async body => {
    try {
      const res = await Axios.post(
        `/storeManagement/images/uploadCategory?categoryId=${body.categoryId}`,
        body.form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      logger.log("upload category image", res);
      return res;
    } catch (error) {
      logger.error("upload category image", error.response);
      return error.response;
    }
  },
  addCategory: async values => {
    set({
      categories: { ...get().categories, addLoading: true },
    });
    try {
      const res = await Axios.post(`/storeManagement/categories`, values);
      logger.log("add category", res);
      return res;
    } catch (error) {
      logger.error("add category", error.response);
      return error.response;
    }
  },
  editCategory: async values => {
    //console.log('category values', values);
    set({
      categories: { ...get().categories, editLoading: true },
    });
    try {
      const res = await Axios.put(`/storeManagement/categories`, values);
      logger.log("edit category", res);
      return res;
    } catch (error) {
      logger.error("edit category", error.response);
      return error.response;
    }
  },
  deleteCategory: async id => {
    //console.log('delete category', id);
    set({
      categories: { ...get().categories, deleteLoading: true },
    });
    try {
      const res = await Axios.delete(`/storeManagement/categories/${id}`);
      logger.log("delete category", res);
      set({
        categories: { ...get().categories, deleteLoading: false },
      });
      return res;
    } catch (error) {
      logger.error("delete category", error.response);
      set({
        categories: { ...get().categories, deleteLoading: false },
      });
      return error.response;
    }
  },
});

export default categories;
