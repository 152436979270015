import Axios from "./api";

const upload = (set, get) => ({
  upload: {
    upload: null,
    count: null,
    failed: null,
    content: null,
    loading: false,
    uploadLoading: false,
    failedLoading: false,
    countLoading: false,
    deleteLoading: false,
    cardInfo: null,
    buyerInfo: null,
    orderInfo: null,
    query: { page: 0, size: 10, sort: "createTime,desc" },
    failedQuery: { page: 0, size: 10, sort: "usedTime,desc" },
    failedContent: null,
    filter: false,
    filterId: null,
    cardsStatistics: {
      cards: null,
    },
  },

  setFilter: filterId => {
    set({
      upload: {
        ...get().upload,
        filter: filterId === "" ? false : true,
        filterId: filterId,
      },
    });
  },
  resetFilter: () => {
    set({
      upload: {
        ...get().upload,
        filter: false,
        filterId: null,
      },
    });
  },

  resetUploadQuery: () => {
    set({
      upload: {
        ...get().upload,
        query: { page: 0, size: 10, sort: "createTime,desc" },
      },
    });
  },
  setUploadQuery: query => {
    set({
      upload: { ...get().upload, query: { ...get().upload.query, ...query } },
    });
  },
  resetFailedQuery: () => {
    set({
      upload: {
        ...get().upload,
        failedQuery: { page: 0, size: 10, sort: "usedTime,desc" },
      },
    });
  },
  setFailedQuery: query => {
    set({
      upload: {
        ...get().upload,
        failedQuery: { ...get().upload.failedQuery, ...query },
      },
    });
  },

  patchFailedCard: async (id, code) => {
    try {
      const res = await Axios.put(
        `/storeManagement/failedCards?failedCardId=${id}&theCorrectCode=${code}`
      );
      //console.log('upload', res);
      return res;
    } catch (error) {
      //console.log(error.response);
    }
  },
  deleteFailedCard: async id => {
    try {
      set({
        upload: {
          ...get().upload,
          deleteLoading: true,
        },
      });
      const res = await Axios.delete(
        `/storeManagement/failedCards?failedCardId=${id}`
      );
      set({
        upload: {
          ...get().upload,
          deleteLoading: false,
        },
      });
      return res;
    } catch (error) {
      //console.log(error.response);
    }
  },

  uploadCards: async (id, form) => {
    try {
      set({
        upload: { ...get().upload, uploadLoading: true },
      });
      const res = await Axios.post(
        `/storeManagement/uploadedCards/text/upload?productId=${id}`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //console.log('upload', res);
      if (res.status === 200) {
        set({
          upload: {
            ...get().upload,
            content: res.data,
            uploadLoading: false,
            upload: res.data?.content,
          },
        });
      }
      return res;
    } catch (error) {
      set({
        upload: { ...get().upload, uploadLoading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
  getCardInfo: async code => {
    try {
      const res = await Axios.get(`/tools/cardInfo?code=${code}`);
      if (res.status === 200) {
        set({
          upload: {
            ...get().upload,
            cardInfo: res?.data,
          },
        });
      }
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  getBuyerInfo: async code => {
    try {
      const res = await Axios.get(`/tools/buyerInfo?code=${code}`);
      if (res.status === 200) {
        set({
          upload: {
            ...get().upload,
            buyerInfo: res?.data,
          },
        });
      }
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  getOrderInfo: async code => {
    try {
      const res = await Axios.get(`/tools/orderInfo?code=${code}`);
      if (res.status === 200) {
        set({
          upload: {
            ...get().upload,
            orderInfo: res?.data,
          },
        });
      }
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
});

export default upload;
