"use client";

import { cn } from "@/lib/utils";
import { useState } from "react";
import Navbar from "./navbar";
import Sidebar from "./sidebar";

const SidebarWithNavbar = ({ children }) => {
  const [isShrunk, setIsShrunk] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <div className="w-full flex">
      <Sidebar
        isShrunk={isShrunk}
        setIsShrunk={setIsShrunk}
        showSidebar={showSidebar}
        setShowSidebar={setShowSidebar}
      />
      <div
        className={cn(
          "flex flex-col absolute top-0 md:start-[290px] start-0 w-[calc(100%-290px)] max-md:w-full transition-[inset-inline-start,inset,width] ease-in-out duration-300 md:gap-4 md:pb-4 bg-muted/40 dark:bg-muted/10",
          isShrunk && "md:start-[68px] w-[calc(100%-68px)]"
        )}
      >
        <Navbar setShowSidebar={setShowSidebar} />
        <div className="w-full flex flex-col min-h-[calc(100vh-60px)] p-4 md:py-0 md:px-6">
          {children}
        </div>
      </div>
    </div>
  );
};

export default SidebarWithNavbar;
