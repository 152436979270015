import { cn } from "@/lib/utils";
import { cva } from "class-variance-authority";
import { forwardRef } from "react";

const paragraphVariants = cva("max-w-prose text-foreground", {
  variants: {
    variant: {
      default: "text-base",
      subTitle: "text-lg font-semibold capitalize",
      title: "text-[32px] font-semibold capitalize",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

const Paragraph = forwardRef(
  ({ className, variant, children, ...props }, ref) => (
    <span
      ref={ref}
      {...props}
      className={cn(paragraphVariants({ className, variant }))}
    > 
      {children}
    </span>
  )
);

Paragraph.displayName = "Paragraph";

export default Paragraph;
