import Axios from "./api";

const notifications = (set, get) => ({
  notification: {
    notification: null,
    content: null,
    loading: false,
    numberNotifications: null,
    countUnreadNotifications: null,
    query: { page: 0, size: 10, sort: "createTime,desc" },
  },

  resetNotificationsQuery: () => {
    set({
      notification: {
        ...get().notification,
        query: { page: 0, size: 10, sort: "createTime,desc" },
      },
    });
  },
  setNotificationsQuery: query => {
    set({
      notification: {
        ...get().notification,
        query: { ...get().notification.query, ...query },
      },
    });
  },

  getAllNotifications: async (loading = true) => {
    try {
      set({
        notification: { ...get().notification, loading },
      });
      const params = {
        ...get().notification.query,
      };

      const res = await Axios.get(`/notification`, { params });
      if (res.status === 200) {
        set({
          notification: {
            ...get().notification,
            content: res.data,
            loading: false,
            notification: res.data?.content,
          },
        });
      }

      return res.data;
    } catch (error) {
      set({
        notification: { ...get().notification, loading: false },
      });
      return error.response;
    }
  },

  getCountOfUnreadNotifications: async () => {
    try {
      const res = await Axios.get(`/notification/countUnread`);
      return res?.data;
    } catch (error) {
      return error.response;
    }
  },
  markAsReadNotification: async () => {
    try {
      await Axios.post(`/notification/markAsRead`);
    } catch (error) {
      return error.response;
    }
  },
});
export default notifications;
