import Axios from "./api";

const flexy = (set, get) => ({
  flexy: {
    networks: null,
    networksContent: null,
    offers: null,
    offersContent: null,
    networkLoading: false,
    offerLoading: false,
    deleteNetworkLoading: false,
    deleteOfferLoading: false,
    query: { page: 0, size: 10, sort: "createTime,desc" },
  },
  resetFlexyQuery: () => {
    set({
      flexy: {
        ...get().flexy,
        query: { page: 0, size: 10, sort: "createTime,desc" },
      },
    });
  },
  setFlexyQuery: query => {
    set({
      flexy: {
        ...get().flexy,
        query: { ...get().flexy.query, ...query },
      },
    });
  },

  getAllNetworks: async (loading, id, query) => {
    try {
      set({
        flexy: { ...get().flexy, loading },
      });
      const params = {
        ...query,
        sort: "createTime,desc",
      };
      const res = await Axios.get(`/storeManagement/networks`, { params });
      //console.log('flexy', res);
      if (res.status === 200) {
        set({
          flexy: {
            ...get().flexy,
            loading: false,
            networks: res.data?.content,
            networksContent: res?.data,
          },
        });
      }
    } catch (error) {
      set({
        flexy: { ...get().flexy, loading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
  createNetwork: async body => {
    try {
      const res = await Axios.post(`/storeManagement/networks`, body);
      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  uploadNetworkImage: async body => {
    try {
      const res = await Axios.post(
        `/storeManagement/images/uploadNetwork?networkId=${body.networkId}`,
        body.form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  updateNetwork: async body => {
    try {
      const res = await Axios.put(`/storeManagement/networks`, body);
      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  updateOffer: async body => {
    try {
      const res = await Axios.put(`/storeManagement/offers`, body);
      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  createOffer: async (id, body) => {
    try {
      const res = await Axios.post(
        `/storeManagement/offers?networkId=${id}`,
        body
      );
      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  deleteOffer: async id => {
    set({
      flexy: { ...get().flexy, deleteOfferLoading: true },
    });
    try {
      const res = await Axios.delete(`/storeManagement/offers/${id}`);
      //console.log(res);
      set({
        flexy: { ...get().flexy, deleteOfferLoading: false },
      });
      return res;
    } catch (error) {
      set({
        flexy: { ...get().flexy, deleteOfferLoading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
  deleteNetwork: async id => {
    set({
      flexy: { ...get().flexy, deleteNetworkLoading: true },
    });
    try {
      const res = await Axios.delete(`/storeManagement/networks/${id}`);
      //console.log(res);
      set({
        flexy: { ...get().flexy, deleteNetworkLoading: false },
      });
      return res;
    } catch (error) {
      set({
        flexy: { ...get().flexy, deleteNetworkLoading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
});

export default flexy;
