"use client";

import { cn } from "@/lib/utils";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const LinkItem = ({ link, isSmall, onClick = () => {} }) => {
  const { t } = useTranslation("layout");
  const [isActive, setIsActive] = useState(false);
  const { pathname } = useRouter();
  const basePath = "/" + pathname.split("/")[1];
  const { name, path, icon } = link;

  useEffect(() => {
    setIsActive(basePath === link.path);
  }, [basePath, link]);

  return (
    <li onClick={onClick}>
      <Link href={path} passHref>
        <a
          className={cn(
            "w-full flex items-center p-[14px] rounded-xl text-muted-foreground transition-[background-color,color,width,margin-start,margin] duration-100 ease-in-out",
            isActive && "bg-primary text-primary-foreground",
            !isActive && "hover:text-foreground",
            isSmall && "w-[54px] ms-[2.5px]"
          )}
        >
          <span
            className={cn(
              "text-[1.5rem] transition-[padding-start,padding] duration-300 ease-in-out",
              isSmall && "ps-[1px]"
            )}
          >
            {icon}
          </span>

          <span
            className={cn(
              "transition-[margin,margin-start,opacity,width] duration-300 ease-in-out overflow-hidden h-[24px] w-auto first-letter:capitalize",
              isSmall ? "opacity-0 w-0" : "opacity-1 ms-[16px]"
            )}
          >
            {t(name)}
          </span>
        </a>
      </Link>
    </li>
  );
};

export default LinkItem;
