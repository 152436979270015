import { cn } from "@/lib/utils";
import { LuLoader2 } from "react-icons/lu";

const sizes = {
  sm: "w-8 h-8",
  md: "w-10 h-10",
  lg: "w-14 h-14",
};

const Spinner = ({ className, size = "sm" }) => {
  return (
    <LuLoader2
      className={cn(
        "animate-spin text-muted-foreground mx-auto",
        sizes[size],
        className
      )}
    />
  );
};

export default Spinner;
