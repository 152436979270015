import { ChakraProvider } from "@chakra-ui/react";
import { DirectionProvider } from "@radix-ui/react-direction";
import { ThemeProvider as NextThemesProvider } from "next-themes";
import { useCallback, useEffect, useState } from "react";
import theme from "theme";
import { Toaster } from "./ui/Sonner";
import { RtlProvider } from "./layout/rtl-provider";

function Providers({ children, Component }) {
  const [dir, setDir] = useState("rtl");

  // Callback to update the direction state
  const updateDirection = useCallback(() => {
    const documentDir = document.dir || "rtl";
    setDir(documentDir);
  }, []);

  useEffect(() => {
    // Set the initial direction
    updateDirection();

    // Observe changes to the document's dir attribute
    const observer = new MutationObserver(updateDirection);
    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ["dir"],
    });

    // Cleanup on component unmount
    return () => observer.disconnect();
  }, [updateDirection]);

  return (
    <ChakraProvider theme={theme}>
      <NextThemesProvider
        forcedTheme={Component.theme || null}
        attribute="class"
        defaultTheme="system"
        enableSystem
      >
        <DirectionProvider dir={dir}>
          <RtlProvider dir={dir}>{children}</RtlProvider>
          <Toaster />
        </DirectionProvider>
      </NextThemesProvider>
    </ChakraProvider>
  );
}

export default Providers;
