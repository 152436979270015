import { useEffect, useRef } from "react";

export default function useEventListener(eventType, callback, element) {
  const callbackRef = useRef(callback);

  // Update the callbackRef if the callback prop changes
  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    // Check if window is defined (client-side rendering)
    const targetElement = element || (typeof window !== "undefined" && window);

    if (!targetElement) return;

    const handler = e => callbackRef.current(e);
    targetElement.addEventListener(eventType, handler);

    return () => targetElement.removeEventListener(eventType, handler);
  }, [eventType, element]);
}
