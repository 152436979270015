import * as React from "react";
import { cva } from "class-variance-authority";

import { cn } from "@/lib/utils";

// Define the color scheme for each role
const badgeVariants = cva(
  "inline-flex items-center rounded-full border text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
        outline: "text-foreground",
        success:
          "border-transparent bg-success text-success-foreground hover:bg-success/80",

        ADMIN: "border-transparent bg-blue-500 text-white hover:bg-blue-600",
        SUPER_GROS:
          "border-transparent bg-purple-500 text-white hover:bg-purple-600",
        GROS: "border-transparent bg-green-500 text-white hover:bg-green-600",
        DETAILS:
          "border-transparent bg-orange-500 text-white hover:bg-orange-600",
        MANAGER: "border-transparent bg-red-500 text-white hover:bg-red-600",
      },
      size: {
        default: "px-2.5 py-0.5",
        sm: "px-1.5 py-0",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

function Badge({ className, variant, size, ...props }) {
  return (
    <div
      className={cn(badgeVariants({ variant, size }), className)}
      {...props}
    />
  );
}

export { Badge, badgeVariants };
