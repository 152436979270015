import {
  Flex,
  Image,
  Spinner,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import useApp from "hooks/useApp";

import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../styles/fonts.css";
import "../styles/globals.css";

import SidebarWithNavbar from "@/components/layout/SidebarWithNavbar";
import { publicRoutes } from "@/data/publicRoutes";
import useI18nLanguageChange from "@/hooks/useI18nLanguageChange";
import Providers from "components/Providers";
import MessengerChat from "components/layout/MessengerChat";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect } from "react";
import "../i18n"; // Import the i18n configuration
import * as gtag from "../lib/gtag";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";


dayjs.extend(relativeTime);

function MyApp({ Component, pageProps, router }) {
  const { authorized } = useApp();
  const rout = useRouter();

  useI18nLanguageChange();

  useEffect(() => {
    const handleRouteChange = url => {
      gtag.pageview(url);
    };

    rout.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      rout.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [rout.events]);

  return (
    <>
      <Script
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=G-C6YWLZG2Y9"
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'G-C6YWLZG2Y9', {
page_path: window.location.href.pathname,
});
`,
        }}
      />
      <Providers Component={Component}>
        {router.asPath === "/logout" ? (
          <Component onent {...pageProps} />
        ) : authorized ? (
          <>
            {publicRoutes.includes(rout.pathname) ? (
              <Component onent {...pageProps} />
            ) : (
              <>
                <MessengerChat />

                <SidebarWithNavbar>
                  <Component onent {...pageProps} />
                </SidebarWithNavbar>
              </>
            )}
          </>
        ) : (
          <Loading />
        )}
      </Providers>
    </>
  );
}

export const Loading = () => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      bg={useColorModeValue("white", "gray.900")}
      h="100vh"
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      {colorMode === "dark" ? (
        <Image mb="8" w="20%" src="/images/logo/hwlogotitle.png" alt=""></Image>
      ) : (
        <Image mb="8" w="20%" src="/images/logo/hblogotitle.png" alt=""></Image>
      )}
      <Spinner color="blue.400" size="xl" />
    </Flex>
  );
};

export default MyApp;
