import i18n from "@/i18n";
import axios from "axios";
import useStore from "store";
import { isTokenExpired } from "utils/isTokenExpired";
import { redirectToLogout } from "utils/redirectToLogout";

export const publicRequest = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API,
});

const Axios = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API,
});

export const storeRequest = axios.create({
  baseURL: process.env.NEXT_PUBLIC_STORE_API,
});

export const billsRequest = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BILLS_API,
});

const configToken = async config => {
  let token = useStore.getState().auth?.access_token;

  if (!token) {
    redirectToLogout();
    return;
  }

  if (isTokenExpired(token)) {
    const getNewAccessToken = useStore.getState().getNewAccessToken;

    if (getNewAccessToken && typeof getNewAccessToken === "function") {
      const newToken = await getNewAccessToken();
      token = newToken;
    }
  }

  // Set the Authorization token in the header
  config.headers.Authorization = `Bearer ${token}`;

  // Add the Accept-Language header
  const currentLanguage = i18n.language || "ar";
  config.headers["Accept-Language"] = currentLanguage;

  return config;
};

// Add a request interceptor to all requests
Axios.interceptors.request.use(configToken, error => Promise.reject(error));

// Add a response interceptor
Axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 403) {
      redirectToLogout();
      return;
    }
    return Promise.reject(error);
  }
);

publicRequest.interceptors.request.use(
  config => {
    const currentLanguage = i18n.language || "ar";
    config.headers["Accept-Language"] = currentLanguage;
    return config;
  },
  error => Promise.reject(error)
);

export const requestWithSpecialToken = (url, method, token) => {
  return publicRequest({
    url,
    method,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export default Axios;
