import { publicRoutes } from "@/data/publicRoutes";
import logdown from "logdown";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import useStore from "store";
import { isTokenExpired } from "utils/isTokenExpired";
import { redirectToLogout } from "utils/redirectToLogout";
//
const logger = logdown("useApp");
logger.state.isEnabled = false;

export default function useApp() {
  const router = useRouter();
  const pathname = router.pathname;
  const [authorized, setAuthorized] = useState(false);
  const isLoggedIn = useStore(state => state.isLoggedIn);
  const isAccountActivate = useStore(state => state.isAccountActivate);
  const isPhoneVerified = useStore(state => state.isPhoneVerified);
  const isPinCreated = useStore(state => state.isPinCreated);
  const token = useStore(state => state.auth?.access_token);
  const getCurrentUser = useStore(state => state.getCurrentUser);
  const getNewAccessToken = useStore(state => state.getNewAccessToken);

  const checkToken = useCallback(async () => {
    if (publicRoutes.includes(pathname)) return;

    if (!token) {
      redirectToLogout();
      return;
    }

    if (isTokenExpired(token)) {
      await getNewAccessToken();
    }

    logger.log("Valid token");
  }, [token]);

  useEffect(() => {
    checkToken();
  }, [checkToken]);

  const authCheck = useCallback(
    url => {
      if (!isLoggedIn && !publicRoutes.includes(pathname)) {
        setAuthorized(false);
        router.push({
          pathname: "/login",
        });
      } else {
        setAuthorized(true);
      }
    },
    [isLoggedIn, router]
  );

  useEffect(() => {
    if (
      isLoggedIn &&
      (!isAccountActivate || !isPhoneVerified || !isPinCreated) &&
      !publicRoutes.includes(pathname)
    ) {
      router.push({
        pathname: "/emailNotVerified",
      });
    }
  }, [
    isAccountActivate,
    isPhoneVerified,
    isPinCreated,
    isLoggedIn,
    router.asPath,
  ]);

  useEffect(() => {
    authCheck(router.asPath);

    router.events.on("routeChangeComplete", authCheck);

    return () => {
      router.events.off("routeChangeComplete", authCheck);
    };
  }, [authCheck, router.events, router.asPath]);

  useEffect(() => {
    isLoggedIn && getCurrentUser();
  }, [isLoggedIn, getCurrentUser]);
  return { authorized };
}
