import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

export default function usePost(
  callback,
  dependencies = [],
  { showErrorToast = false } = {}
) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation("common");

  const handlePost = useCallback(
    (...data) => {
      setIsLoading(true);
      setError(null);
      callback(...data)
        .catch(error => {
          const errorMessage =
            error?.response?.data?.message ??
            (error.response?.status == 502
              ? t("errors.update_in_progress")
              : t("errors.something_wrong"));
          if (showErrorToast) {
            toast.error(errorMessage);
          }
          setError(errorMessage);
        })
        .finally(() => setIsLoading(false));
    },
    [callback, ...dependencies]
  );

  return { isLoading, error, handlePost };
}
