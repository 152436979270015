import Axios from "./api";

const profits = (set, get) => ({
  profits: {
    profits: null,
    content: null,
    loading: false,
    addProfitToBalanceLoading: false,
    productsProfits: null,
    serviceProfits: null,
    categoryProfits: null,
    networkProfits: null,
    productContent: null,
    serviceContent: null,
    categoryContent: null,
    networkContent: null,
    query: { page: 0, size: 10, sort: "createTime,desc" },
  },

  addProfitToBalance: async amount => {
    try {
      set({
        profits: { ...get().profits, addProfitToBalanceLoading: true },
      });
      const params = {
        amount,
      };
      const res = await Axios.post(`/profits/addToCredit`, {}, { params });
      // console.log("add profit to balance", res);

      if (res.status === 202) {
        set({
          profits: {
            ...get().profits,
            addProfitToBalanceLoading: false,
          },
        });
      }
      return res;
    } catch (error) {
      set({
        profits: { ...get().profits, addProfitToBalanceLoading: false },
      });
      return error.response;
    }
  },
});

export default profits;
