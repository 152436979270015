import { cn } from "@/lib/utils";
import Spinner from "./Spinner";

const WithLoader = ({ isLoading, children, className }) => {
  return isLoading ? (
    <Spinner className={cn("mt-[25vh] mx-auto", className)} />
  ) : (
    children
  );
};

export default WithLoader;
