import { Button } from "@/components/ui/Button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/Tooltip";
import { cn } from "@/lib/utils";
import useStore from "@/store";
import { useTranslation } from "react-i18next";
import { PiEyeBold, PiEyeSlashBold } from "react-icons/pi";

const ShowBalanceToggle = () => {
  const { t } = useTranslation("layout");
  const showBalance = useStore(state => state.showBalance);
  const toggleShowBalance = useStore(state => state.toggleShowBalance);

  const tooltipContent = t(showBalance ? "hide_balance" : "show_balance");

  return (
    <TooltipProvider delayDuration={300}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            className="text-[1.3rem] rounded-full p-0 h-9 w-9 relative"
            onClick={toggleShowBalance}
          >
            <PiEyeBold
              className={cn(
                "rotate-0 scale-100 transition-transform",
                showBalance && "scale-0"
              )}
            />
            <PiEyeSlashBold
              className={cn(
                "absolute scale-0 transition-transform",
                showBalance && "scale-100"
              )}
            />
            <span className="sr-only">{tooltipContent}</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>{tooltipContent}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default ShowBalanceToggle;
