import { Button } from "@/components/ui/Button";
import useStore from "@/store";
import Image from "next/image";
import Link from "next/link";
import { useEffect } from "react";
import { Trans } from "react-i18next";

const SupplierContactLink = ({ children }) => {
  const getSuperUser = useStore(state => state.getSuperUser);
  const superUserPhone = useStore(state => state.superUser?.phone);

  useEffect(() => {
    if (superUserPhone) return;

    getSuperUser();
  }, [getSuperUser]);

  return (
    <Link href={`tel:${superUserPhone}`} passHref>
      <Button
        variant="link"
        className="underline text-white h-fit py-0 px-2 text-base max-[450px]:text-sm"
      >
        {children}
      </Button>
    </Link>
  );
};

const AccountFrozen = () => {
  return (
    <div className="bg-sky-900 w-full text-white relative overflow-hidden">
      <Image
        src="/images/snow-falling.png"
        alt="snow falling"
        objectFit="cover"
        layout="fill"
        className="pb-16 opacity-70"
      />

      <div className="min-h-[36px] mx-auto px-2 sm:px-4 md:px-8 max-w-7xl">
        <span className="max-[450px]:text-sm mx-auto font-medium flex justify-center w-fit text-center text-base backdrop-blur-sm p-1 rounded-md">
          <Trans
            ns="layout"
            i18nKey="account_frozen"
            components={{
              SupplierContactLink: <SupplierContactLink />,
            }}
          />{" "}
        </span>
      </div>
    </div>
  );
};

export default AccountFrozen;
