import { getEPaymentLink } from "utils/getEPaymentLink";
import Axios from "./api";

const orders = (set, get) => ({
  orders: {
    confirmedOrder: null,
    orders: null,
    content: null,
    orderOffers: null,
    loading: false,
    loadingFlexyOrder: false,
    loadingOrderOffers: false,
    lastOrders: null,
    loadingLastOrder: false,
    consumptions: null,
    consumptionsLoading: false,
    cardLoading: { status: false, id: null },
    items: null,
    loadingItems: false,
    contentItem: null,
    query: { page: 0, size: 10, sort: "createTime,desc" },
    lastQuery: { page: 0, size: 10, sort: "createTime,desc" },
    itemsQuery: { page: 0, size: 10, sort: "code,desc" },
  },

  resetOrderQuery: () => {
    set({
      orders: {
        ...get().orders,
        query: { page: 0, size: 10, sort: "createTime,desc" },
      },
    });
  },
  setOrderQuery: query => {
    set({
      orders: { ...get().orders, query: { ...get().orders.query, ...query } },
    });
  },
  resetItemQuery: () => {
    set({
      orders: {
        ...get().orders,
        itemsQuery: { page: 0, size: 10, sort: "code,desc" },
      },
    });
  },
  setItemQuery: query => {
    set({
      orders: {
        ...get().orders,
        itemsQuery: { ...get().orders.itemsQuery, ...query },
      },
    });
  },
  getAllOrders: async loading => {
    try {
      set({
        orders: { ...get().orders, loading },
      });
      const params = {
        ...get().orders.query,
      };

      const res = await Axios.get(`/orders`, { params });
      if (res.status === 200) {
        const onlyOrders = [];
        res.data?.content?.map(res => onlyOrders.push(res));
        set({
          orders: {
            ...get().orders,
            content: res.data,
            loading: false,
            orders: onlyOrders,
          },
        });
      }
    } catch (error) {
      set({
        orders: { ...get().orders, loading: false },
      });
      return error.response;
    }
  },
  getLastOrders: async (loading = true) => {
    try {
      set({
        orders: { ...get().orders, loadingLastOrder: loading },
      });
      const params = {
        ...get().orders.lastQuery,
      };
      const res = await Axios.get(`/orders`, { params });

      if (res.status === 200) {
        set({
          orders: {
            ...get().orders,
            loadingLastOrder: false,
            lastOrders: res.data?.content,
          },
        });
      }
    } catch (error) {
      set({
        orders: { ...get().orders, loadingLastOrder: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },

  getConsumptions: async (loading, start, end) => {
    try {
      set({
        orders: { ...get().orders, consumptionsLoading: loading },
      });
      const res = await Axios.get(
        `/orders/consumptions?start=${start}&end=${end}`
      );
      if (res.status === 200) {
        set({
          orders: {
            ...get().orders,
            consumptionsLoading: false,
            consumptions: res.data,
          },
        });
      }
    } catch (error) {
      set({
        orders: { ...get().orders, consumptionsLaoading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
  reportOrder: async (id, detail) => {
    try {
      const res = await Axios.post(
        `/orders/complaint?orderId=${id}&details=${detail}`
      );
      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  orderCard: async (id, withEPayment) => {
    set({
      orders: { ...get().orders, cardLoading: { status: true, id } },
    });
    try {
      const path = withEPayment ? "/ePayOrders" : "/orders";
      const res = await Axios.post(path + `/product?productId=${id}`);
      //console.log(res);
      set({
        orders: { ...get().orders, cardLoading: { status: false, id: null } },
      });

      if (withEPayment) {
        window.location.href = getEPaymentLink({
          orderId: res?.data?.id,
          username: res?.data?.user?.username,
          amount: res?.data?.amount,
          type: "card",
        });
      }

      return res;
    } catch (error) {
      set({
        orders: { ...get().orders, cardLoading: { status: false, id: null } },
      });
      //console.log(error.response);
      return error.response;
    }
  },

  getConfirmedOrder: async orderId => {
    try {
      const res = await Axios.get("/orders/get", {
        params: {
          id: orderId,
        },
      });
      set({ orders: { ...get().orders, confirmedOrder: res.data } });
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },

  orderFlexy: async body => {
    try {
      const res = await Axios.post(`/orders/flexy`, body);
      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  orderNewFlexy: async (body, withEPayment, onCloseAlert = () => {}) => {
    set({
      orders: { ...get().orders, loadingFlexyOrder: true },
    });

    try {
      const path = withEPayment ? "/ePayOrders/flexy" : "/orders/newFlexy";

      const res = await Axios.post(path, body);

      if (withEPayment) {
        window.location.href = getEPaymentLink({
          orderId: res?.data?.id,
          username: res?.data?.user?.username,
          amount: res?.data?.amount,
          type: "flexy",
        });
      }

      return res;
    } catch (error) {
      return error.response;
    } finally {
      onCloseAlert();
      setTimeout(() => {
        set({
          orders: { ...get().orders, loadingFlexyOrder: false },
        });
      }, 0);
    }
  },

  resendNormalFlexyOrder: async orderId => {
    set({
      orders: { ...get().orders, loadingFlexyOrder: true },
    });

    try {
      const res = await Axios.post("/orders/reSendFlexy", null, {
        params: { orderId },
      });

      return res;
    } catch (error) {
      throw error;
    } finally {
      setTimeout(() => {
        set({
          orders: { ...get().orders, loadingFlexyOrder: false },
        });
      }, 0);
    }
  },

  orderNewFlexySabaqqli: async orderId => {
    const res = await Axios.post("/orders/flexySabbaqli?orderId=" + orderId);
    return res;
  },

  getCardImage: async serial => {
    try {
      const res = await Axios.get(`/orders/cardImage?serial=${serial}`);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  orderMultipleCards: async (id, cardsNumber, withEPayment, fileType) => {
    const path = withEPayment ? "/ePayOrders" : "/orders";
    // console.log(cardsNumber);
    try {
      const res = await Axios.post(
        path +
          `/multipleCards?productId=${id}&cardsNumber=${cardsNumber}&type=${fileType}`
      );

      if (withEPayment) {
        window.location.href = getEPaymentLink({
          orderId: res?.data?.id,
          username: res?.data?.user.username,
          amount: res?.data?.amount,
          type: "card",
        });
      }

      return res;
    } catch (error) {
      return error.response;
    }
  },

  downloadByOrderId: async (id, fileType) => {
    try {
      const res = await Axios.get(
        `/orders/downloadOrderFile?orderId=${id}&type=${fileType}`
      );
      return res;
    } catch (error) {
      return error.response;
    }
  },
  reportItemOrder: async (id, detail, itemId) => {
    try {
      const res = await Axios.post(
        `/orders/report?orderId=${id}&details=${detail}&itemId=${itemId}`
      );
      return res;
    } catch (error) {
      return error.response;
    }
  },

  createOrderOffers: async phoneNbr => {
    try {
      set({
        orders: {
          ...get().orders,
          loadingOrderOffers: true,
        },
      });
      const res = await Axios.post(`/orders/offers?number=${phoneNbr}`);
      set({
        orders: {
          ...get().orders,
          loadingOrderOffers: false,
          orderOffers: res.data,
        },
      });

      return res;
    } catch (error) {
      set({
        orders: {
          ...get().orders,
          loadingOrderOffers: false,
        },
      });
      return error.response;
    }
  },
  activateInternetCard: async (id, values) => {
    try {
      const res = await Axios.post(
        `/orders/activationInternet?orderId=${id}&number=${values.number}`
      );
      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  activation: async (id, values) => {
    try {
      const res = await Axios.post(
        `/orders/activation?productId=${id}&playerId=${values.number}`
      );
      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
});

export default orders;
