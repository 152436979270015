import logdown from "logdown";
import Axios from "./api";

const logger = logdown("transactions");
logger.state.isEnabled = false;

const transactions = (set, get) => ({
  debtTrackingUsers: {
    debtTrackingUsers: null,
    debtTrackingUsersSum: null,
    content: null,
    loading: false,
    query: { page: 0, size: 10, sort: "createdAt,asc" },
  },
  transactions: {
    transactions: null,
    transactionsSum: null,
    content: null,
    contentSum: null,
    sumSubCustomers: null,
    loading: false,
    query: { page: 0, size: 10, sort: "createTime,desc" },
    queryTo: { page: 0, size: 10, sort: "createTime,desc" },
    querySum: { page: 0, size: 10, sort: "createTime,desc" },
  },
  resetTransactionsQuery: numberCellTable => {
    set({
      transactions: {
        ...get().transactions,
        query: { page: 0, size: 10, sort: "createTime,desc" },
        queryTo: { page: 0, size: 10, sort: "createTime,desc" },
        querySum: { page: 0, size: numberCellTable, sort: "createTime,desc" },
      },
    });
  },
  setTransactionsQuery: query => {
    // console.log("I enterrrr");
    set({
      transactions: {
        ...get().transactions,
        query: { ...get().transactions.query, ...query },
      },
    });
  },
  setTransactionsSumQuery: query => {
    set({
      transactions: {
        ...get().transactions,
        querySum: { ...get().transactions.querySum, ...query },
      },
    });
  },
  setTransactionsToQuery: query => {
    // console.log("I enterrrr to sum");
    set({
      transactions: {
        ...get().transactions,
        queryTo: { ...get().transactions.queryTo, ...query },
      },
    });
  },
  getAllTransactions: async loading => {
    try {
      set({
        transactions: { ...get().transactions, loading },
      });
      const params = {
        ...get().transactions.query,
      };

      const res = await Axios.get(`/transactions`, { params });
      //  logger.log(res);
      if (res.status === 200) {
        set({
          transactions: {
            ...get().transactions,
            content: res.data,
            loading: false,
            transactions: res.data?.content,
          },
        });
      }
    } catch (error) {
      set({
        transactions: { ...get().transactions, loading: false },
      });
      //console.log(error.response);
    }
  },
  getSumTransactions: async (start, end, filterPayment = false) => {
    try {
      set({
        transactions: { ...get().transactions, loading: true },
      });
      const params = { ...get().transactions.querySum, filterPayment };

      const res = await Axios.get(
        `/transactions/sumBetween?start=${start}&end=${end}`,
        { params }
      );
      if (res.status === 200) {
        set({
          transactions: {
            ...get().transactions,
            contentSum: res?.data,
            transactionsSum: res?.data?.content,
          },
        });
      }
      return res;
    } catch (error) {
      //   console.log("Error Transactions between" + error);
      //console.log(error.response);
    } finally {
      set({
        transactions: { ...get().transactions, loading: false },
      });
    }
  },
  getToTransactions: async (loading, username) => {
    try {
      set({
        transactions: { ...get().transactions, loading },
      });
      const params = {
        ...get().transactions.queryTo,
      };
      const res = await Axios.get(`/transactions/${username}`, { params });

      if (res.status === 200) {
        set({
          transactions: {
            ...get().transactions,
            content: res.data,
            loading: false,
            transactions: res.data?.content,
          },
        });
      }
    } catch (error) {
      set({
        transactions: { ...get().transactions, loading: false },
      });
      //console.log(error.response);
    }
  },
  creditCharge: async values => {
    try {
      const res = await Axios.post(`/transactions/charge`, values);
      return res;
    } catch (error) {
      return error.response;
      //console.log(error.response);
    }
  },
  debt: async (values, id) => {
    try {
      const res = await Axios.post(`transactions/debt/${id}`, values);
      return res;
    } catch (error) {
      return error.response;
      //console.log(error.response);
    }
  },
  credit: async (values, id) => {
    try {
      const res = await Axios.post(`transactions/credit/${id}`, values);
      return res;
    } catch (error) {
      return error.response;
      //console.log(error.response);
    }
  },
  payment: async (values, id) => {
    try {
      const res = await Axios.post(`/transactions/payer/${id}`, values);
      return res;
    } catch (error) {
      return error.response;
      //console.log(error.response);
    }
  },
  restore: async (values, id) => {
    try {
      const res = await Axios.post(`/transactions/restore/${id}`, values);
      return res;
    } catch (error) {
      return error.response;
      //console.log(error.response);
    }
  },
  transferChargeAuto: async values => {
    try {
      const res = await Axios.post(`/transactions/chargeAuto`, values);
      return res;
    } catch (error) {
      return error.response;
    }
  },

  returnFromAdmin: async (values, toId) => {
    try {
      const res = await Axios.post(
        `/transactions/returnFromAdmin/${toId}`,
        values
      );
      return res;
    } catch (error) {
      return error.response;
    }
  },

  resetDebtTrackingUserQuery: () => {
    set({
      debtTrackingUsers: {
        ...get().debtTrackingUsers,
        query: { page: 0, size: 10, sort: "createdAt,asc" },
      },
    });
  },

  setDebtTrackingUsersQuery: query => {
    set({
      debtTrackingUsers: {
        ...get().debtTrackingUsers,
        query: { ...get().debtTrackingUsers.query, ...query },
      },
    });
  },

  getDebtTrackingUsers: async loading => {
    try {
      set({
        debtTrackingUsers: { ...get().debtTrackingUsers, loading },
      });

      const params = {
        ...get().debtTrackingUsers.query,
      };

      const res = await Axios.get("/debtTracking/users", {
        params,
      });
      set({
        debtTrackingUsers: {
          ...get().debtTrackingUsers,
          content: res.data,
          loading: false,
          debtTrackingUsers: res.data?.content,
        },
      });
    } catch (error) {
      set({
        debtTrackingUsers: { ...get().debtTrackingUsers, loading: false },
      });
      return error.response;
    }
  },
});

export default transactions;
