import jwt_decode from "jwt-decode";

export const isTokenExpired = token => {
  try {
    const decodedToken = jwt_decode(token);
    const currentDate = Date.now();
    return decodedToken.exp * 1000 < currentDate;
  } catch (error) {
    return true;
  }
};
