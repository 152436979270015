export const publicRoutes = [
  "/login",
//   "/logout",
  "/register",
  "/register-phone",
  "/",
  "/forgot",
  "/reset",
  "/ar",
  "/fr",
  "/en",
  "/verifyEmail",
  "/verifyCode",
  "/verifyPin",
  "/oauth2/redirect",
];
