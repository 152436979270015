"use client";

import { useRouter } from "next/router";
import { useEffect } from "react";
import { CustomChat, FacebookProvider } from "react-facebook";

const MessengerChat = () => {
  const { pathname } = useRouter();
  const hideChat = !pathname.startsWith("/tickets");

  useEffect(() => {
    const toggleHideChat = () => {
      if (hideChat) {
        FB.CustomerChat.hide();
      } else {
        FB.CustomerChat.show(false);
      }
    };

    // Ensure the global FB object is available before attempting to call its methods
    if (typeof FB !== "undefined") {
      toggleHideChat();
      FB.Event.subscribe("customerchat.load", toggleHideChat);
    }

    return () => {
      if (window.FB) {
        FB.Event.unsubscribe("customerchat.load", toggleHideChat);
      }
    };
  }, [hideChat]);

  if (hideChat) return null;

  return (
    <FacebookProvider appId="1457461908530846" chatSupport>
      <CustomChat pageId="101709819364986" minimized={true} />
    </FacebookProvider>
  );
};

export default MessengerChat;
