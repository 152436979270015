import Axios from "./api";
const percentage = (set, get) => ({
  percentage: {
    percentage: null,
    content: null,
    loading: false,
    query: { page: 0, size: 10, sort: "createTime,desc" },
  },
  currentPercentage: null,

  resetPercentageQuery: () => {
    set({
      percentage: {
        ...get().percentage,
        query: { page: 0, size: 10, sort: "createTime,desc" },
      },
    });
  },
  setPercentageQuery: query => {
    set({
      percentage: {
        ...get().percentage,
        query: { ...get().percentage.query, ...query },
      },
    });
  },

  getCurrentPercentage: async () => {
    try {
      const res = await Axios.get(`percentages/currentPercentage`);
      if (res.status === 200) {
        set({
          currentPercentage: res.data,
        });
      }
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  editPercentage: async ({ id, type, percentage }) => {
    try {
      const res = await Axios.patch(`/percentages/${type}/${id}`, {
        percentage,
      });
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
});

export default percentage;
