import Axios from "./api";

const tools = (set, get) => ({
  tools: {
    flexyOperations: null,
    content: null,
    query: { page: 0, size: 20, sort: "createTime,desc" },
  },
  setFlexyQuery: (query) => {
    set({
      tools: {
        ...get().tools,
        query: { ...get().tools.query, ...query },
      },
    });
  },
  getFlexyOperation: async (code) => {
    try {
      const params = {
        ...get().tools.query,
      };
      const res = await Axios.get(`/tools/orders?number=${code}`, { params });
      if (res.status === 200) {
        set({
          tools: {
            ...get().tools,
            content: res?.data,
            flexyOperations: res?.data?.content,
          },
        });
      }
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  getFlexyOperationByOrderId: async (id) => {
    try {
      const res = await Axios.get(`/tools/flexyOperationByOrder?orderId=${id}`);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
});

export default tools;
