import Axios from "./api";

const contacts = (set, get) => ({
  contacts: {
    contacts: null,
    content: null,
    loading: false,
    editLoading: false,
    deleteLoading: false,
    addLoading: false,
    query: { page: 0, size: 10, sort: "createTime,desc" },
    queryFlexy: { page: 0, size: 500, sort: "createTime,desc" },
    isGetSearch: false,
    search: null,
    type: "all",
  },
  toggleGetSearch: isGetSearch => {
    set({
      contacts: { ...get().contacts, isGetSearch },
    });
  },
  setSearch: search => {
    set({
      contacts: { ...get().contacts, search },
    });
  },
  resetContactQuery: () => {
    set({
      contacts: {
        ...get().contacts,
        query: { page: 0, size: 10, sort: "createTime,desc" },
      },
    });
  },
  setContactQuery: query => {
    set({
      contacts: {
        ...get().contacts,
        query: { ...get().contacts.query, ...query },
      },
    });
  },
  getAllContacts: async (loading = true, id, All) => {
    try {
      set({
        contacts: { ...get().contacts, loading },
      });
      const params = null;
      if (All) {
        params = { ...get().contacts.queryFlexy };
      } else {
        params = { ...get().contacts.query };
      }

      const type = get().contacts.type;

      const res = await Axios.get(`/contacts`, { params: { ...params, type } });

      if (res.status === 200) {
        set({
          contacts: {
            ...get().contacts,
            content: res.data,
            loading: false,
            contacts: res.data?.content,
          },
        });
      }
    } catch (error) {
      set({
        contacts: { ...get().contacts, loading: false },
      });
      return error.response;
    }
  },

  setContactsType: type => {
    set({
      contacts: {
        ...get().contacts,
        type,
      },
    });
  },

  resetContacts: () => {
    set({
      contacts: {
        ...get().contacts,
        content: null,
        loading: false,
        contacts: [],
      },
    });
  },

  getContactsBySearch: async (loading, values, fetchAll = false) => {
    //console.log('values', values);
    try {
      set({
        contacts: { ...get().contacts, loading },
      });
      const params = fetchAll
        ? { page: 0, size: 50, sort: "createTime,desc" }
        : {
            ...get().contacts.query,
          };

      const res = await Axios.get(
        `/contacts/${values.url}?${values.url}=${values.search}`,
        {
          params,
        }
      );
      //console.log('contacts', res);
      if (res.status === 200) {
        set({
          contacts: {
            ...get().contacts,
            content: res.data,
            loading: false,
            contacts: res.data?.content,
          },
        });
      }
    } catch (error) {
      set({
        contacts: { ...get().contacts, loading: false },
      });
      //console.log(error.response);
      return error.response;
    }
  },
  addContact: async values => {
    get().resetContactQuery();

    set({
      contacts: { ...get().contacts, addLoading: true },
    });
    try {
      const res = await Axios.post(`/contacts`, values);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  editContact: async values => {
    //console.log('category values', values);
    set({
      contacts: { ...get().contacts, editLoading: true },
    });
    try {
      const res = await Axios.put(`/contacts`, values);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  deleteContact: async id => {
    set({
      contacts: { ...get().contacts, deleteLoading: true },
    });
    try {
      const res = await Axios.delete(`contacts/${id}`);
      //console.log('delete res', res);
      set({
        contacts: { ...get().contacts, deleteLoading: false },
      });
      return res;
    } catch (error) {
      //console.log(error.response);
      set({
        contacts: { ...get().contacts, deleteLoading: false },
      });
      return error.response;
    }
  },
});

export default contacts;
