import i18n from "@/i18n";
import dayjs from "dayjs";
import "dayjs/locale/ar";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import { useEffect } from "react";

const useI18nLanguageChange = () => {
  useEffect(() => {
    const updateDirectionAndLang = lang => {
      document.documentElement.setAttribute("lang", lang);
      dayjs.locale(lang);

      if (lang === "ar") {
        document.documentElement.setAttribute("dir", "rtl");
      } else {
        document.documentElement.setAttribute("dir", "ltr");
      }
    };

    updateDirectionAndLang(i18n.language);

    i18n.on("languageChanged", updateDirectionAndLang);

    // Clean up listener on unmount
    return () => {
      i18n.off("languageChanged", updateDirectionAndLang);
    };
  }, []);
};

export default useI18nLanguageChange;
