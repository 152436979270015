import Axios from "./api";

const settings = (set, get) => ({
  isTokenValid: false,
  resendPhoneLoading: false,
  changePhoneLoading: false,
  verifyExistingPhoneLoading: false,
  ConfirmPhoneLoading: false,
  changePhone: async values => {
    set({ changePhoneLoading: true });
    try {
      const res = await Axios.put(`/settings/updatePhone`, {
        newPhone: values?.newPhone,
        password: values?.password,
      });
      set({ changePhoneLoading: false });
      return res;
    } catch (err) {
      set({ changePhoneLoading: false });
      return err.response;
    }
  },

  verifyExistingPhone: async () => {
    set({ verifyExistingPhoneLoading: true });
    try {
      const res = await Axios.get(`/settings/sendVerificationPhone`);
      set({ verifyExistingPhoneLoading: false });
      return res;
    } catch (err) {
      set({ verifyExistingPhoneLoading: false });
      return err.response;
    }
  },

  confirmChangePhone: async pin => {
    set({ ConfirmPhoneLoading: true });
    try {
      const res = await Axios.post(
        `/settings/confirmChangedPhone?token=${pin}`
      );
      set({ ConfirmPhoneLoading: false });
      return res;
    } catch (err) {
      set({ ConfirmPhoneLoading: false });
      return err.response;
    }
  },

  confirmExistingPhone: async pin => {
    set({ ConfirmPhoneLoading: true });
    try {
      const res = await Axios.post(
        `/settings/confirmExistingPhone?token=${pin}`
      );
      set({ ConfirmPhoneLoading: false });
      return res;
    } catch (err) {
      set({ ConfirmPhoneLoading: false });
      return err.response;
    }
  },
});

export default settings;
