import Axios from "./api";

const subCategories = (set, get) => ({
  subCategories: {
    subCategories: null,
    content: null,
    loading: false,
    editLoading: false,
    deleteLoading: false,
    addLoading: false,
    query: { page: 0, size: 10, sort: "createTime,desc" },
  },
  resetSubCategoriesQuery: () => {
    set({
      subCategories: {
        ...get().subCategories,
        query: { page: 0, size: 10, sort: "createTime,desc" },
      },
    });
  },
  setSubCategoriesQuery: (query) => {
    set({
      subCategories: {
        ...get().subCategories,
        query: { ...get().subCategories.query, ...query },
      },
    });
  },
  getAllSubCategories: async (loading, id, query) => {
    try {
      set({
        subCategories: { ...get().subCategories, loading },
      });
      const params = {
        ...get().subCategories.query,
      };
      const res = await Axios.get(
        `/storeManagement/subCategories?categoryId=${id}`,
        { params }
      );
      //console.log('subCategories', res);
      if (res.status === 200) {
        set({
          subCategories: {
            ...get().subCategories,
            content: res.data,
            loading: false,
            subCategories: res.data?.content,
          },
        });
      }
    } catch (error) {
      set({
        subCategories: { ...get().subCategories, loading: false },
      });
      //console.log(error.response);
    }
  },

  uploadSubCategoryImage: async (body) => {
    try {
      const res = await Axios.post(
        `/storeManagement/images/uploadSubCategory?subCategoryId=${body.subCategoryId}`,
        body.form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error.response);
      return error.response;
    }
  },
  addSubCategory: async (id, values) => {
    set({
      subCategories: { ...get().subCategories, addLoading: true },
    });
    try {
      const res = await Axios.post(
        `/storeManagement/subCategories?categoryId=${id}`,
        values
      );
      return res;
    } catch (error) {
      //console.log(error.response);
    }
  },
  editSubCategory: async (values) => {
    //console.log('category values', values);
    set({
      subCategories: { ...get().subCategories, editLoading: true },
    });
    try {
      const res = await Axios.put(
        `/storeManagement/subCategories?categoryId=${values.category.id}`,
        values
      );
      return res;
    } catch (error) {
      //console.log(error.response);
      set({
        subCategories: { ...get().subCategories, editLoading: false },
      });
    }
  },
  deleteSubCategory: async (id) => {
    //console.log('delete category', id);
    set({
      subCategories: { ...get().subCategories, deleteLoading: true },
    });
    try {
      const res = await Axios.delete(`/storeManagement/subCategories/${id}`);
      //console.log('delete res', res);
      set({
        subCategories: { ...get().subCategories, deleteLoading: false },
      });
      return res;
    } catch (error) {
      //console.log(error.response);
      set({
        subCategories: { ...get().subCategories, deleteLoading: false },
      });
    }
  },
});

export default subCategories;
