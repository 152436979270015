import Axios from "@/store/api";

export const fetchUserPaymentPercentage = async () => {
  const res = await Axios.get("/paymentPercentages");
  return res.data;
};

export const activePaymentPercentageForUser = async (username, day) => {
  const res = await Axios.post("/paymentPercentages", null, {
    params: {
      username,
      day,
    },
  });

  return res.data;
};

export const disablePaymentPercentageForUser = async username => {
  const res = await Axios.put("/paymentPercentages/disable", null, {
    params: {
      username,
    },
  });
  return res.data;
};
